.login-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 800px;
    min-height: 500px;
    border-radius: 30px;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    background: white;
    padding: 20px;
    padding-bottom: 50px;
    margin-bottom: 80px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .login-form label {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: black; /* Make label text black */
    font-weight: bold; /* Optional: makes text bold */
    width: 300px;
  }
  
  .login-form input {
    margin-top: 5px;
    padding: 10px;
    width: 100%; /* Change to 100% from 93% to make input width consistent */
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .login-form button {
    margin-top: 20px;
    padding: 10px 20px;
    border: none;
    background: #a84141;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    margin-right: 40px;
  }
  
  .login-form button:hover {
    background: #555;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    padding: 0;
    margin: 0;
  }