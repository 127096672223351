.signup-modal{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    margin: auto;
    /* height: auto; */
    padding-top: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px #04053a4d;
    border-radius: 16px;
    margin-top: 5%;
    max-width: 400px;
}

.signup-modal h1 {
    color: #5c6bc0;
    margin-bottom: 20px;
}

.signup-form{
    width: 84%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;  
}

.signup-form ul {
    padding: 0;
    color: red;
    margin-bottom: 15px;
}

.signup-form label {
    width: 100%;
    margin-bottom: 12px;
    color: #444;
    font-size: 12px;
}

.signup-form input {
    width: calc(100% - 20px);
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.signup-form button{
    padding: 10px 20px;
    color: white;
    background-color: #d9534f;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 10px;
    margin-top: 20px;
}

.signup-form button:hover{
    background-color: #c9302c
}