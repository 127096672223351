.footer {
    background-color: #2e3856;
    color: #fefefe;
    margin-top: 30px;
    padding: 20px 0; 
}

.footer-header {
    font-size: 18px;
    text-align: center;
    margin-bottom: 10px; 
}

.about-us {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    width: 50%;
    align-items: center;
}

.user-info {
    font-size: 22px; 
    text-align: center;
}

.icons {
    font-size: 40px; 
    width: 100px; 
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 10px auto; 
    color: lightblue;
}

.github-icon, .linkedin-icon{
    color: white;
}