.flashcard-grid {
    display: grid;
    grid-gap: 36px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    list-style: none;
    padding: 20px;
}

.options-view-label {
    color: #f6f7fb;
    font-size: 1.2rem;
}

.flashcard {
    position: relative;
    width: 100%;
    height: 200px;
    transition: transform 150ms, box-shadow 150ms; 
    font-size: 1.2rem;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    perspective: 1000px;
    border-radius: 10px;
    cursor: pointer;
}
.flashcard-question, .flashcard-answer{
    font-family: "Open Sans", "PT Sans", Calibri, Tahoma, sans-serif;
}

.flashcard:hover {
    transform: scale(1.03);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
}

.flip-container {
    width: 100%;
    height: 100%;
    position: relative;
}

.front, .back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: transform 0.6s;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    box-sizing: border-box;
    word-wrap: break-word;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.front {
    transform: rotateY(0deg);
}

.back {
    transform: rotateY(180deg);
}

.flashcard.flipped .flip-container .front {
    transform: rotateY(-180deg);
}

.flashcard.flipped .flip-container .back {
    transform: rotateY(0deg);
}

.edit-icon {
    position: absolute;
    bottom: 10px;
    cursor: pointer;
    background-color: white;
    padding: 5px;
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.edit-icon:hover, .ellipsis:hover {
    transform: scale(1.2);
}

.edit-icon {
    left: 10px;
    border: #333;
}

.ellipsis {
    right: 10px;
    position: absolute;
    bottom: 10px;
    cursor: pointer;
    background-color: white;
    width: 30px; 
    height: 30px; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    border-radius: 50%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
}

.delete-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.myflashcards-container {
    padding: 20px
}

.myflashcards-heading{
    font-size: 1.8rem;
    font-weight: bold;
    color: white;
    padding-bottom: 20px;
    margin-bottom: 5px;
    text-align: left;
}
.flashcard-sets-section {
    margin-top: 40px;
    padding: 20px;
}

.flashcard-sets-grid {
    display: grid;
    grid-gap: 36px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    list-style: none;
    padding: 20px;

}

.flashcard-set {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 15px; 
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 150ms, box-shadow 150ms;
    cursor: pointer;
}

.flashcard-set:hover{
    transform: scale(1.03);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
}

.flashcard-set h3 {
    margin: 0;
    font-size: 12px;
    font-family: "Open Sans", "PT Sans", Calibri, Tahoma, sans-serif;
}

.flashcard-set p {
    margin: 10px 0;
    font-size: 1rem;
    font-family: "Open Sans", "PT Sans", Calibri, Tahoma, sans-serif;
}

.pagination-and-filter-options-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.username {
    font-style: italic;
    font-size: 0.9rem;
}

h2 {
    font-family: Inter, sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #f6f7fb;
}

.view-label {
    color: #f6f7fb;
    font-size: 1.2rem;
    margin-right: 10px;
}

.option-select {
    padding: 5px 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f6f7fb;
    color: #333;
    cursor: pointer;
    transition: border-color 0.3s;
    width: 10%
}

.option-select:hover {
    border-color: #666;
}



.spacer{
    flex: 1;
}

.filter-option-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; /* adjust as needed */
}

.filter-option-container .options-view-label{
    margin-bottom: 15px;
}


/* Quizzes css on homepage */

.quizzes-section {
    margin-top: 40px;
    padding: 20px;
}

.quiz-sets-grid {
    display: grid;
    grid-gap: 36px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    list-style: none;
    padding: 20px;

}

.individual-quiz {
    margin: 20px 0;
    padding: 20px;
    border-radius: 15px; 
    background-color: #2e3856;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 150ms, box-shadow 150ms;
    cursor: pointer;
    height: 150px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.individual-quiz h3 {
    color: #D9DDE8;
}

.individual-quiz:hover{
    transform: scale(1.03);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
}

.pagination-controls{
    /* flex: 1; */
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
    /* width: 30px;
    height: 30px; */
}

.shuffle-button {
    color: whitesmoke;
    background-color: #0056b3;
    border-radius: 10px;
    cursor: pointer;
    width: 100px;
    padding: 0 20px;
    margin-left: 2rem;
    border: none;
    /* justify-content: flex-start; */
}

.pagination-button {
    cursor: pointer;
}

.page-navigator-container{
    display: flex;
    flex-direction: row;
    padding: 0 20px;
}

.pagination-controls span{
    color: whitesmoke;
    margin: 10px;
}

@media screen and (max-width: 750px){
    .flashcard-grid{
      display: flex;
      flex-direction: column;
      font-size: 20px;
    }
    .flashcard-grid .flashcard-question{
        font-size: 1.2rem !important;
    }
}

@media screen and (max-width: 600px){
    .filter-option-container{
        display: none;
    }
    .options-view-label{
        display: none;
    }
}