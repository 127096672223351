@font-face {
    font-family: 'Quicksand', sans-serif;
}

@font-face {
    font-family: 'Darker Grotesque', sans-serif;
}

@font-face {
    font-family: 'Roboto', sans-serif;
}


/* .quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
} */

.quiz-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10% auto;
}

.quiz h1{
    color: white;
}

.question {
    position: relative;
    background-color: #f8f8f8;
    border-left: 5px solid #1da1f2;
    width: 80%;
    margin: 10% auto;
    padding: 3rem;
    /* height: 450px; */
    /* transform: translateY(-20%); */
}

h5 {
    font-size: 1.2rem;
    margin-bottom: 3rem;
    line-height: 1.5;
    text-align: center;
}

.option-container {
    display: inline-block;
    width: 50%;
}

.option {
    background-color: #1da1f2;
    border-radius: 30px;
    color: #f8f8f8;
    cursor: pointer;
    margin: 2rem auto;
    padding: 1rem;
    transition: 0.3s linear all;
    text-align: center;
    width: 90%;
    display: block;
}

.option:hover {
    background-color: #333;
}

.option:active {
    background-color: #005cb2;
}

.buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;
    color: black;
    text-align: center;
    margin: 1rem 0;
}

.buttonContainer button {
    border: none;
    color: black;
    cursor: pointer;
    font-family: 'Quicksand', sans-serif;
    padding: 0.5rem 1rem;
}

.navigation-buttons{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem auto;
    width: 100%;
}

.prev-question-button {
    flex: 1;
    text-align: left;
    padding-left: 2rem; 

}

.next-question-button {
    flex: 1;
    text-align: right;
    padding-right: 2rem; 
}

.toast-valid{
    background-color: #4caf50;
    border-radius: 5px !important;
    color: white
}

.toast-invalid{
    background-color: #F44336;
    border-radius: 5px !important;
    color: white
}

.current-question{
    position: absolute;
    top: 10px;
    left: 10px;
    color: black;
}


h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}


.buttonContainer ul{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.buttonContainer li{
    padding: 0;
    margin: 0;
}

.play-button {
    background-color: lightgreen;
    border: none;
    color: white;
    padding: 12px 20px;
    text-align: center;
    text-decoration: none;
    justify-content: center;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 30px;
    transition: background-color 0.3s ease;
    width: 300px;
}

.play-button:hover {
    background-color: #0056b3;
}

.quiz {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.quiz-mode{
    text-align: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 40px;
    position: absolute;
    bottom: 10%;
    top: 1.5%;
    transform: translateX(-5%);
}

.quiz-buttons {
    cursor: pointer;
    color: whitesmoke;
    background-color: #3c4b96;
    font-style: bold;
    padding: 10px;
    border: none;
    border-radius: 10px;
}

