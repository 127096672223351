.login-form-container {
    width: 1000px;
    margin: 125px auto;

  }
  
  .login-form-title {
    display: flex;
    margin: 20px 0px;
    flex-direction: column;
    background-color: white;
    width: 40%;
    margin: 0 auto;
    padding: 20px 40px;
    height: 500px;
    box-shadow: 0px 2px 17px 1px rgba(0, 0, 0, 0.4);
    border-radius: 10px;
  }
  
  .login-form-page {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 40%;
    margin: 0 auto;
    padding: 20px 40px;
    height: 500px;
    box-shadow: 0 4px 17px 1px rgba(0, 0, 0, .4);
    border-radius: 10px;
  } 

  .login-form-page > h1 {
    text-align: center;
    color: #5c6bc0;
  }

  .login-form-page-email {
    display: flex;
    margin: 20px 0;
    flex-direction: column;
  }

  .login-page-email-for-icon {
    position: relative;
  }

  .login-email-icon{
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 18px;
  }

  .signup-lock-sign {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(23%);
    font-size: 18px;
  }

  .email-label, .password-label {
    margin-bottom: 5px;
    font-weight: normal !important;
  }

  .login-page-password {
    position: relative;
  }
  
  .login-form-signup-button {
    text-align: center;
  }

  .login-form-signup-button a {
    color:#5c6bc0;
  }
  
  .login-form ul {
    list-style: none;
    padding: 0;
    margin-bottom: 1rem;
  }
  
  .login-form ul li {
    color: red; /* Errors color */
    margin-bottom: 0.5rem;
  }
  
  .login-input {
    padding: 0.75rem !important; /* 12px */
    border-radius: 4px !important;
    border: 1px solid #ccc !important;
    font-size: 16px !important;
    display: block !important;
    width: 100% !important; /* It will take the padding into account automatically */
    box-sizing: border-box !important; /* Makes sure padding doesn't add to the width */
    margin-top: 0.5rem !important;
    margin-bottom: 1rem !important;
  }
  
  .login-input-email#email {
    padding: 10px 0px;
    width: 100%;
    font-size: 16px;
    padding-left: 30px;
  }

  .login-input-password{
    padding: 10px 0;
    width: 100%;
    font-size: 16px;
    margin-top: 5px;
  }

  .login-btn {
    width: 100%;
    padding: 0.75rem;
    margin-top: 1rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .login-btn-submit {
    background-color: #5c6bc0 !important;
    color: #fff !important;
    margin-top: 20px !important;
    padding: 10px 5px !important;
    font-size: 20px !important;
    cursor: pointer !important;
    transition: transform .3s !important;
    border: none !important;
    width: 100% !important;
  }
  
  .login-btn-demo {
    background-color: #5c6bc0 !important;
    color: #fff !important;
    margin-top: 20px !important;
    padding: 10px 5px !important;
    font-size: 20px !important;
    cursor: pointer !important;
    transition: transform .3s !important;
    border: none !important;
    width: 100% !important;
  }
  
  .login-btn:hover {
    /* Add a subtle hover effect for buttons */
    opacity: 0.9;
  }

  .login-form-page-password {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    flex-direction: column;
  }