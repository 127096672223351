.flashcard-form {
    width: 40%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    transform: translateY(30%);
    border-radius: 25px;
}

.flashcard-form > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* Allows each div to grow and fill the space */
    margin-bottom: 20px; /* Consistent margin for all divs */
}

label {
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
}

/* input[type="text"] {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    width: 100%; 
} */
/* select {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
} */


button[type="create"] {
    background-color: #0056b3;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 15px;
    width: 100%;
}

button[type="submit"]:hover {
    background-color: #0056b3;
}

.error-validation {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

/* .question-input, .answer-input{
    width: 100%;
} */

#flashcard-question, #flashcard-input{
    width: 100% !important;
    padding: 8px !important;
    border-radius: 5px !important;
    border: 1px solid #ddd !important;
    font-size: 16px !important;
}

.topic-select{
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
}          