/* * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
} */

/* Overall Font */
body {
    font-family: 'Quicksand', sans-serif;
}

/* Main Results Container */
.results {
    text-align: center;
    padding: 20px;
}

/* Heading */
.results h1 {
    color: #57b846;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.check-icon {
    fill: green;
}


.quiz-results {
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: inline-block;
    padding: 20px;
    max-width: 600px;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
}


.quiz-results h3 {
    color: #595959;
    margin-bottom: 1rem;
    text-align: center;
}

.stat {
    color: #333;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}


.quiz-results::after {
    content: "";
    display: table;
    clear: both;
}


section ul {
    list-style-type: none;
    padding-top: 20px;
}

.play-again-button{
    background-color: #4ab364;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 15px;  
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.home-button {
    background-color: #1da1f2;
    color: white;
    border: none;
    padding: 10px 20px;
    margin: 15px;  
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0c7abf;
}

.stat-score-middle {
    font-size: 30px;
    color: rgb(0, 132, 255);
    margin: 1rem auto;
    width: fit-content;
    display: block;
}

/* Responsive Design */
@media (max-width: 768px) {
    .results h1 {
        font-size: 2rem;
    }

    .quiz-results {
        max-width: 90%;
        padding: 15px;
    }

    .stat {
        font-size: 1rem;
    }
}