.flashcard-sets-grid {
    display: grid;
    grid-gap: 36px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    list-style: none;
    padding: 20px;
    background-color: whitel;
}

.flashcard-sets-section {
    margin-top: 40px;
    padding: 20px;
}

.flashcard-set {
    border-radius: 15px; /* Slight border radius */
    background-color: #2e3856;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 150ms, box-shadow 150ms;
    cursor: pointer;
    height: 150px;
    width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 20px 0;
}

.flashcard-set:hover{
    transform: scale(1.03);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, .3);
}

.flashcard-set h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #D9DDE8;
}

.flashcard-set p {
    margin: 10px 0;
    font-size: 1rem;
    color: #D9DDE8;
}

.username {
    font-style: italic;
    font-size: 0.9rem;
}

.trash-icon-flashcardset {
    /* position: absolute;
    top: 10px;
    right: 10px; */
    cursor: pointer;
    transition: 0.3s ease;
    /* border: 1px solid #D9DDE8; */
}

.trash-icon-flashcardset:hover{
    transform: scale(1.10);
    border-color: #D9DDE8
}

.set-edit-icon {
    bottom: 5px;
    position: absolute;
    left: 5px;
    cursor: pointer;
    border: 1px solid #333;
    transition: transform 0.3s ease;
}

.set-edit-icon:hover{
    transform: scale(1.10);
    border-color: black
}