.flashcard-set-form-container {
    display: flex;
    align-items: flex-start; /* Changed this to flex-start */
    justify-content: center;
    width: 100%;
    padding-top: 10vh; /* Add some padding at the top to push the form down */
}

.flashcard-set-form {
    max-width: 550px;
    width: 50%;
    margin: auto;
    padding: 25px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #ADD8E6;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    /* Removed the transform property */
}

.label-class {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 8px;
    color: #333;
    font-size: 1.1em;
    gap: 10px
}

#flashcard-s-form input[type="text"] {
    width: 97%;
    padding: 8px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin-right: 30px;
}

.create-flashcard-set-button {
    padding: 10px 20px;
    font-size: 1em;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;/* Spacing between buttons */
}

button[type="button"] {
    background-color: rgb(35, 134, 199);
    color: white;
    width: 50%;
}

button[type="submit"] {
    background-color: rgb(35, 134, 199);
    color: white;
    width: 50%;
}

.create-flashcard-set-button button + button {
    margin-left: 20px; /* Spacing between the "Add Card" button and the "Submit" button */
}

button:hover {
    opacity: 0.8;
}

.set-error-validation {
    color: red;
    font-size: 14px;
    width: auto;
    text-align: center; /* Centering error validation message */
    margin-top: 5px;
}

.question-answer-label label {
    color: black; /* setting the color to black */
}

.question-answer-label {
    gap: 5px;
}