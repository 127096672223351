.delete-confirmation-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: lightblue;
    border-radius: 10px;
    padding: 10px
}

.delete-button{
    color: white;
    background-color: darkblue;
    cursor: pointer;
    margin: 10px;
    padding: 10px
}

.cancel-button {
    color: white;
    background-color: darkblue;
    cursor: pointer;
    margin: 10px;
    padding: 8px 25px;
}

.delete-text{
    text-align: center;
    font-size: 1.5em;
    font-weight: bold;
}