.quiz-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    margin: auto;
    padding: 20px;
}

.quiz-form {
    width: 100%;
    max-width: 600px; 
    margin: auto;
    padding: 20px;
    background-color: #ADD8E6;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quiz-form label, .quiz-title, .question-block > label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: black;
}

.quiz-option-label {
    justify-content: center;
    align-items: center;
}
.question-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    gap: 5px;
}

.quiz-form .quiz-title-input,
.quiz-form .quiz-correct-answer-input {
    width: 80%; 
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.quiz-option-input {
    border: none;
    width: 100%;
    padding: 10px;
}

.quiz-form .quiz-question-input{
    width: 70%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
}


/* Button Styling */
.quiz-form .quiz-button,
.quiz-form .quiz-create-button, .quiz-button-add-question-btn[type="button"] {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 132px;
}

.quiz-form .quiz-button:hover,
.quiz-form .quiz-create-button:hover {
    background-color: #0056b3;
}

/* .quiz-form .add-question-btn {
    background-color: #0056b3;
}

.quiz-form .add-question-btn:hover {
    background-color: #0056b3;
} */

/* Question Blocks */
.quiz-form .question-block {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Error Message Styling */
.quiz-form .error-validation {
    color: red;
    margin-bottom: 10px;
}

.correct-answer-label{
    justify-content: center;
    align-items: center;
}

/* .quiz-title-input{
    margin-left: 50px;
} */

/* .quiz-title-label {
 margin: auto;
} */

.quiz-title-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.delete-question-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    color: red;
    font-size: 20px;
}

/* .quiz-form-label {
    margin: 0 auto;
} */

.option-label-and-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.correct-label-and-answer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}