.icon {
  background: none;
  border: none;
}

.fa-user-circle {
  font-size: 30px;
  color: #3e69a9;
  background-color: transparent;
}

.profile-icon-button{
  height: 70%;
  margin-top: 30px;
  margin-right: 20px;
  cursor: pointer;
}

.fa-user-circle:hover {
  color: #626368;
  cursor: pointer;
}
.profile-dropdown {
  position: absolute;
  margin-top: 70px;
  right: 50px;
  padding: 10px;
  box-shadow: 8 8px 16px 0 rgba(32, 94, 151, 0.15);
  background-color: #0a092d;
  border-radius: 10px;
  z-index: 100;
  border: .225rem solid #282e3e;
}

.user-information {
  border-bottom: 1px solid #696868;
  padding: 10px;
  color: whitesmoke;
  list-style-type: none;
}
.list-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.list-button button {
  padding: 5px 20px;
  border: none;
  border-radius: 10px;
}

.list-button button:hover {
  cursor: pointer;
}

.hidden {
  display: none;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.nav-bar-container {
  padding: .5rem 1rem;
  box-sizing: border-box;
}


.signup-login {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 0px 15px;
  height: 150px;
}

.signup-login > button {
  border: none;
  padding: 5px 18px;
  border-radius: 10px;
  cursor: pointer;
}

.no-user {
  margin-bottom: 70px;
}

a {
  text-decoration: none;
  background-color: transparent;
  color: whitesmoke;
}

.emt-logo-container {
  align-items: center;
  display: inline-flex;
  padding-right: 1.5rem;
}

.emt-logo img {
 height: 82px;
 width: 150px;
}

.search-bar-and-icon-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}


.flashcard-links, .create-quiz-link {
  margin-top: 15px;
}

.flashcard-links-and-search-container {
  align-items: center;
  display: inline-flex;
  flex-grow: 1;
  justify-content: center;
}

.create-flashcard,
.create-flashcard-set {
    display: inline-block; /* Ensures the width matches the text */
    margin-bottom: 5px;
    color: white;
    margin-right: 20px;
    font-weight: bold;
    position: relative; 
    transition: 200ms cubic-bezier(0.08,0.52,0.52,1);
    /* transition: 200ms cubic-bezier(0.08,0.52,0.52,1); */
}

.create-flashcard:hover, .create-flashcard-set:hover, .create-quiz:hover {
  /* transition: 200ms cubic-bezier(0.08,0.52,0.52,1); */
  color: lightblue;
}

.create-quiz{
  display: inline-block;
  margin-bottom: 5px;
  color: white;
  margin-right: 20px;
  font-weight: bold;
  position: relative;
  text-decoration: none;
  background-color: transparent;
  transition: 200ms cubic-bezier(0.08,0.52,0.52,1);
}



.create-quiz-link{
margin-left: auto;
display: flex;
align-items: center;
margin-right: 30px;
/* margin-right: 130px; */
}

.search-bar-padding-container {
  width: 100%;
  max-width: 38rem;
  position: relative;
  height: 100%;
}

.topic-bar{
  padding: 0px 10px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: #cecece 1px solid;
  margin-bottom: 20px;
}

.topic-contents{
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 20px;
  position: relative;
}
.topic-contents a {
  color: #FFF9D9;
  position: relative;
}

.topic-contents a::after {
  content: "";
  height: 2px;
  background-color: #FFDAB9; 
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  transition: width 0.3s ease-in-out;
}

.topic-contents a:hover::after {
  width: 100%;
}

.create-flashcard:hover::after,
.create-flashcard-set:hover::after,
.create-quiz:hover::after {
    width: 100%; 
}

.create-flashcard a::after, .create-flashcard-set a::after, .create-quiz a::after{
    content: '';
    height: 2px;
    /* background-color: #7583ff; */
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
}

.search-bar-container {
  background: none;
  display: flex;
  flex-grow: 1;
  padding: 0;
  align-items: center;
  justify-content: center;
}

.search-form{
  height: 40px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: fow
}

.search-input-wrapper {
  border: .120rem solid var(--gray-300-gray-700);
  color: var(--gray-800-gray-400);
  background-color: var(--gray-300-gray-700);
  border-radius: .4rem;
  box-sizing: border-box;
  flex-flow: row nowrap;
  justify-content: flex-start;
  min-height: 2rem;
  height: 2.5rem;
  padding: 0;
  font-weight: 500;
}

/* .search-icon {
  position: absolute;
  left: 0; 
  top: 50%; 
  transform: translateY(-79%); 
  z-index: 10; 
  font-size: 20px; 
  color: white;
  width: 23px; 
  height: 35px; 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
} */

.search-icon {
  height: 1.5rem;
  width: 1.5rem;
  color: white;
  gap: 10px;
  margin: auto;
}

.search-icon-container {
  background: none;
  padding-left: .625rem;
  padding-right: .625rem;
  padding-top: .4rem;
  position: absolute;
  z-index: 101;
  width: 50%;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.globalSearchBar {
  height: 3.0rem;
  /* margin: -.1875rem .3125rem 4.0rem 2.5625rem; */
  display: flex;
  background-color: #2e3856;
  border-radius: 10px;
  width: 50%;
  flex-grow: .2;
  margin-right: 20%;
}

.search-bar {
 display: flex;
 align-items: center;
 position: relative;
 height: 100%;
 border-radius: 10px;
 background-color: #2e3856;
 border: none;
 width: 90%;
 color: #f0f0f0;
 padding-left: 5px;
}

@media screen and (max-width: 1000px){
  .topic-bar{
    display: none;
  }
}

@media screen and (max-width: 660px){
  .flashcard-links{
    display: flex;
    flex-direction: column;
  }
  .search-bar-container{
    display: none;
  }
  .create-quiz-link{
    display: none;
  }
}
